<template>
  <div>
    <section class="selfcare-title d-flex">
      <div>
        <a class="btn btn-link btn-sm" style="color: #000" @click="goBack()">
          <svg
            fill="var(--text-color)"
            class="material-design-icon__svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
          >
            <path
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            ></path>
          </svg>
        </a>
      </div>
    </section>

    <section class="p-2 terms container_wrapper">
      <div class="container">
        <h5>
          <strong>
            {{ $t("privacyPolicy") }}
          </strong>
        </h5>

        <h6>
          <strong> {{ $t("privacyPolicyPurpose") }} </strong>
        </h6>
        <p>
          {{ $t("yourPrivacyPolicy") }}
        </p>

        <p>{{ $t("describesInformation") }}</p>
        <ol>
          <li>
            {{ $t("describesInformation1") }}
          </li>
          <li>
            {{ $t("describesInformation2") }}
          </li>
        </ol>
        <h6>
          {{ $t("whatTypeOfInformation") }}
        </h6>

        <ol>
          <li>
            {{ $t("collectPlayerInformation.introduction") }}
          </li>
        </ol>

        <ul>
          <li>
            {{ $t("collectPlayerInformation.setupAccount") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.receiveQuestions") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.complyRegulations") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.informFutureEvents") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.manageAccount") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.provideServices") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.verifyAccuracy") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.prepareStatistics") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.individualizedMarketing") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.accountCommunications") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.conformLegalRequirements") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.investigateImproperActivity") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.reportCrime") }}
          </li>
          <li>
            {{ $t("collectPlayerInformation.performContractualObligations") }}
          </li>
        </ul>
        <ol>
          <li>{{ $t("communicationPolicies.unsolicitedInformation") }}</li>
          <li>{{ $t("communicationPolicies.usernameUsage") }}</li>
          <li>{{ $t("communicationPolicies.callRecording") }}</li>
          <li>{{ $t("communicationPolicies.informationTransfer") }}</li>
          <li>{{ $t("communicationPolicies.stopProcessingRequest") }}</li>
        </ol>
        <p>{{ $t("updatingInformation") }}</p>
        <p>{{ $t("playerUpdateInstructions") }}</p>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from "../../../components/Footer.vue";


export default {
  name: "privacy",
  components: {Footer},
  mounted: function () {
    //
  },
  methods: {
    //
  },
};
</script>

<style src="./index.css" scoped></style>
